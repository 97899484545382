(function (factory) {
    typeof define === 'function' && define.amd ? define(['kendo.core'], factory) :
    factory();
})((function () {
    (function( window, undefined$1 ) {
        kendo.cultures["da"] = {
            name: "da",
            numberFormat: {
                pattern: ["-n"],
                decimals: 2,
                ",": ".",
                ".": ",",
                groupSize: [3],
                percent: {
                    pattern: ["-n %","n %"],
                    decimals: 2,
                    ",": ".",
                    ".": ",",
                    groupSize: [3],
                    symbol: "%"
                },
                currency: {
                    name: "",
                    abbr: "",
                    pattern: ["-n $","n $"],
                    decimals: 2,
                    ",": ".",
                    ".": ",",
                    groupSize: [3],
                    symbol: "kr."
                }
            },
            calendars: {
                standard: {
                    days: {
                        names: ["søndag","mandag","tirsdag","onsdag","torsdag","fredag","lørdag"],
                        namesAbbr: ["sø","ma","ti","on","to","fr","lø"],
                        namesShort: ["sø","ma","ti","on","to","fr","lø"]
                    },
                    months: {
                        names: ["januar","februar","marts","april","maj","juni","juli","august","september","oktober","november","december"],
                        namesAbbr: ["jan","feb","mar","apr","maj","jun","jul","aug","sep","okt","nov","dec"]
                    },
                    AM: [""],
                    PM: [""],
                    patterns: {
                        d: "dd-MM-yyyy",
                        D: "d. MMMM yyyy",
                        F: "d. MMMM yyyy HH:mm:ss",
                        g: "dd-MM-yyyy HH:mm",
                        G: "dd-MM-yyyy HH:mm:ss",
                        m: "d. MMMM",
                        M: "d. MMMM",
                        s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                        t: "HH:mm",
                        T: "HH:mm:ss",
                        u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                        y: "MMMM yyyy",
                        Y: "MMMM yyyy"
                    },
                    "/": "-",
                    ":": ":",
                    firstDay: 1
                }
            }
        };
    })();

}));
